import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  VideoIntro,
} from 'layouts';
import {
  Banner,
  // Breadcrumbs,
} from 'components';
import { BASE_URL } from 'config';

// const BREADCRUMBS = [
//   {
//     name: 'About Us',
//     absolute_slug: PAGES.ABOUT_BRAND.PATH,
//   },
//   {
//     name: PAGES.ABOUT_SCHOLARSHIPS.TITLE,
//     absolute_slug: PAGES.ABOUT_SCHOLARSHIPS.PATH,
//   },
// ];

const AboutWelcomeFarewellPage = () => (
  <Layout page="about/our-haka-welcome-haka-and-farewell">

    <Helmet
      title="Our Maori Welcome, Haka and Farewell for our Tour Guests | Haka Tours, NZ"
      meta={[
        {
          name: 'description', content: `Proud of our New Zealand roots,
          our culture is incredibly important to us, which is why we weave equal measures of culture and adventure into all our small group tours.`,
        },
        { name: 'og:title', content: 'Our Maori Welcome, Haka and Farewell for our Tour Guests | Haka Tours, NZ' },
        {
          name: 'og:description', content: `Proud of our New Zealand roots,
          our culture is incredibly important to us, which is why we weave equal measures of culture and adventure into all our small group tours.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-haka-welcome-haka-and-farewell/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/about-page-banner.jpg?fit=crop&auto=format">
      <h1 className="c-heading c-heading--h0">THE HAKA TOURS MIHI, HAKA & POROPOROAKI</h1>
    </Banner>
    <section className="l-section">
      <VideoIntro
        title=""
        videoUrl="https://youtu.be/ejYiAUfUcg0?t=9"
        hideReviews
        disableVideoPromo={false}
      >
        <h4 className="c-heading c-heading--h4">MIHI & POROPOROAKI: HOW WE GREET & FAREWELL GUESTS</h4>
        <p>
          Our Mihi is a speech that our Tour Managers give at the start of each tour to welcome our manuhiri (guests) on tour and to greet them in body, soul and spirit.
          We also lead the new tour group through the hongi and explain that the sharing of breath or life force is a symbolic show of unity between two people.
          In this way, the hongi and our mihi help to strengthen the connection and sense of cohesion of the group before we start on our journey,
          and uphold our values of <a href="https://hakatours.com/about-us/our-values/">manaakitanga and whanaungatanga</a>.
          At the end of the tour, our Tour Managers will give a Poroporoaki– or farewell speech to bring the tour to a close.
        </p>
      </VideoIntro>

      <VideoIntro
        title=""
        videoUrl="https://youtu.be/uC3d_obt7Rc"
        hideReviews
        disableVideoPromo={false}
      >
        <h4 className="c-heading c-heading--h4">OUR HAKA TOURISM GROUP HAKA</h4>
        <p>
          As part of our tours, we also teach our guests a Māori haka. In the past we have taught them the ‘Ka Mate’ haka,
          but we felt that a haka more tailored to our guests and their experiences was needed, so we asked our Māori Cultural Advisor, Eru,
          to write and develop a haka for Haka Tourism Group. Using our whole body, spirit and soul to honour the people we are meeting,
          the haka creates a sense of connection with Aotearoa and the tour group.
          The Haka haka acknowledges that travel can be a powerful transformative experience that enables us to break down our own barriers and boundaries to become someone who stands tall and feels positive and strong.
        </p>
      </VideoIntro>
    </section>

    {/* <BlogList */}
    {/* title="The Latest Haka News" */}
    {/* list={[1, 2]} */}
    {/* /> */}

  </Layout>
);

export default AboutWelcomeFarewellPage;
